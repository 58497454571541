<template>
	<div class="home">
		<div class="header">
			<div class="logo">
				<img src="../assets/logo.png" alt="">
				<p>蒸汽猫无人健身房数据平台</p>
				<div style="margin-left: 20px;">
					<a-date-picker v-model="dateValue" format="YYYY-MM-DD" @change="getStoreList" />
				</div>
			</div>
			<a-dropdown placement="bottomRight">
				<div class="user-box">
					<!-- <img :src="userData.avatar" /> -->
					<img :src="userData.avatar ? userData.avatar : userImg" alt="">
					<span>{{userData.nickName}}</span>
				</div>
				<template #overlay>
					<a-menu>
						<a-menu-item @click="logout">
							<a href="javascript:;"><a-icon type="logout" style="margin-right: 8px;" />退出登录</a>
						</a-menu-item>
					</a-menu>
				</template>
			</a-dropdown>
		</div>

		<div class="list">
			<div class="item" v-for="(item, index) in storeList" :key="item.storeId">
				<div class="top">
					<img :src="userImg" class="logoimg" alt="" />
					<div class="date">
						{{getDateText}}
					</div>
				</div>
				<div class="title">{{item.storeName}}</div>

				<div class="datas" v-if="item.activeType == 1">
					<div class="data-item">
						<div class="data-text">
							<p class="name">本月收入: {{item.currMonthIncome}}</p>
							<div class="link" @click="getStoreDetail(item, '10')">查看明细<a-icon
									:class="{'iconactive': item.queryType10Show, 'iconted': true}" type="down" /></div>
						</div>
						<div class="chil2" v-if="item.queryType10Show">
							<p v-for="(child, idx) in item.queryType10">
								{{child.name}}: {{child.val}}
							</p>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name">今日收入: {{item.currDayIncome}}</p>
							<div class="link" @click="getStoreDetail(item, '20')">查看明细<a-icon
									:class="{'iconactive': item.queryType20Show, 'iconted': true}" type="down" /></div>
						</div>
						<div class="chil2" v-if="item.queryType20Show">
							<p v-for="(child, idx) in item.queryType20">
								{{child.name}}: {{child.val}}
							</p>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name">本月支出: {{item.currMonthExpense}}</p>
							<div class="link" @click="getStoreDetail(item, '30')">查看明细<a-icon
									:class="{'iconactive': item.queryType30Show, 'iconted': true}" type="down" /></div>
						</div>
						<div class="chil2" v-if="item.queryType30Show">
							<div v-for="(child, idx) in item.queryType30">
								<div class="ppbx">
									<p>{{child.name}}: {{child.val}}</p>
									<div class="link" v-if="child.name == '耗材'"
										@click="getStoreDetail(item, '31', child.name)">查看明细<a-icon
											:class="{'iconactive': item.queryType31Show, 'iconted': true}"
											type="down" /></div>
								</div>
								<div class="chil2" style="padding-left: 38px;" v-if="item.queryType31Show && child.name == '耗材'">
									<p v-for="(child3d, idx3d) in item.queryType31">
										{{child3d.name}}: {{child3d.val}}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name" style="width: 250px;">本月利润: {{item.currMonthProfit}}</p>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name" style="width: 250px;">总投资成本: {{item.cost}}</p>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name" style="width: 250px;">回本进度: {{item.rate}}</p>
						</div>
					</div>
				</div>

				<div class="datas" v-if="item.activeType == 2">
					<div class="data-item">
						<div class="data-text">
							<p class="name" style="width: 250px;">本月人次: {{item.currMonthFinishCount}}</p>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name" style="width: 250px;">本日人次: {{item.currDayFinishCount}}</p>
						</div>
					</div>
					<div class="data-item">
						<div class="data-text">
							<p class="name" style="width: 250px;">当前存续月卡会员数量: {{item.monthMemberCount}}</p>
						</div>
					</div>
				</div>

				<div class="types-box">
					<div class="types">
						<span :class="{'active': item.activeType == 1}" @click="item.activeType = 1">财务数据</span>
						<span :class="{'active': item.activeType == 2}" @click="item.activeType = 2">运营数据</span>
					</div>
				</div>

				<div class="textboto" @click="showModel(item.storeId, item.storeName)">查看图表</div>
			</div>
		</div>


		<a-modal v-model="visible" :dialog-style="{ top: '30px' }" :footer="null" width="1200px"
			:title="storeTitle + ' - 数据图表'">
			<div style="margin-bottom: 40px;margin-top: -10px;">
				<div class="titlebx">财务数据</div>
				<div>
					<a-tabs size="small" v-model="setType1ShowValue" @change="setType1Show">
						<a-tab-pane key="1" tab="支出">
						</a-tab-pane>
						<a-tab-pane key="2" tab="收入" force-render>
						</a-tab-pane>
						<a-tab-pane key="3" tab="利润">
						</a-tab-pane>
						<a-tab-pane key="4" tab="收入、支出、利润">
						</a-tab-pane>
					</a-tabs>
				</div>
				<a-spin :spinning="spinningTop">
					<div style="margin-top: -5px;margin-bottom: 10px;" v-if="setType1ShowValue == 2">
						<a-select size="small" v-model="queryTypeCwSr" style="width: 80px;" @change="getIncome">
							<a-select-option value="day">
								日度
							</a-select-option>
							<a-select-option value="month">
								月度
							</a-select-option>
						</a-select>
					</div>
					<div>
						<div ref="echarts" style="height: 280px;width: 1100px;"></div>
					</div>
				</a-spin>
			</div>
			<div>
				<div class="titlebx">运营数据</div>
				<div>
					<a-tabs size="small" v-model="setType2ShowValue" @change="setType2Show">
						<a-tab-pane key="1" tab="人次">
						</a-tab-pane>
						<a-tab-pane key="2" tab="当前存续月卡会员数量" force-render>
						</a-tab-pane>
					</a-tabs>
				</div>
				<a-spin :spinning="spinningBot">
					<div style="margin-top: -5px;margin-bottom: 10px;" v-if="setType2ShowValue == 1">
						<a-select size="small" v-model="queryTypeYyRc" style="width: 80px;" @change="getOrderCount">
							<a-select-option value="day">
								日度
							</a-select-option>
							<a-select-option value="month">
								月度
							</a-select-option>
						</a-select>
					</div>
					<div>
						<div ref="echartsbox1" style="height: 280px;width: 1100px;"></div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '../http'
	import userImg from '../assets/acatve.png'
	import dayjs, {
		Dayjs
	} from 'dayjs';
	export default {
		name: 'home',
		data: function() {
			return {
				userData: {},
				userImg,

				storeList: [],

				visible: false,
				storeId: 0,
				queryTypeYyRc: 'day',
				queryTypeYksl: 'day',
				queryTypeCwSr: 'day',
				queryTypeCwLl: 'day',
				setType1ShowValue: '1',
				setType2ShowValue: '1',
				spinningTop: true,
				spinningBot: true,
				storeTitle: '',

				dateValue: dayjs(new Date()).format("YYYY-MM-DD"),
			}
		},
		computed: {
			getDateText() {
				return dayjs(this.dateValue).format("YYYY-MM-DD")
			}
		},
		mounted() {
			if (!localStorage.getItem('token')) {
				this.$message.error('请先登录！');
				this.$router.push('/login')
			} else {
				this.getUserInfo()
				this.getStoreList()
			}
			console.log(this.$refs.echarts, 'this.$refs.echartsthis.$refs.echarts')
		},
		methods: {
			onDateChange() {

			},
			getUserInfo() {
				http({
					method: 'get',
					url: '/prod-api/getInfo',
					data: {

					}
				}).then((res) => {
					if (res.code == 200) {
						this.userData = res.user
					} else {
						localStorage.removeItem('token')
						this.$message.error(res.msg);
						this.$router.push('/login')
					}
				})
			},
			getStoreList() {
				// let res = {
				// 	"msg": "操作成功",
				// 	"code": 200,
				// 	"data": [{
				// 		"storeId": 8, //门店id
				// 		"storeName": "雨花春江路店", //门店名称
				// 		"currMonthIncome": "398.00", //本月收入
				// 		"currDayIncome": "19.90", //今日收入
				// 		"currMonthExpense": "49.80", //本月支出
				// 		"currMonthProfit": "348.20", //当月利润
				// 		"cost": "100000.00", //投资成本
				// 		"rate": "0.4100%", //回本进度
				// 		"currMonthFinishCount": "580", //本月人次
				// 		"currDayFinishCount": "15", //本日人次
				// 		"monthMemberCount": "0", //当前存续⽉卡会员数量

				// 		"queryType10": [],
				// 		"queryType20": [],
				// 		"queryType30": [],
				// 		"queryType31": [],
				// 		"queryType10Show": false,
				// 		"queryType20Show": false,
				// 		"queryType30Show": false,
				// 		"queryType31Show": false,

				// 		"activeType": 1
				// 	}]
				// }

				http({
					method: 'post',
					url: '/prod-api/system/store/data/list',
					data: {
						"dates": dayjs(this.dateValue).format("YYYY-MM-DD") //查询日期
					}
				}).then((res) => {
					if (res.code == 200) {
						this.storeList = res.data.map((item) => {
							return {
								...item,
								"queryType10": [],
								"queryType20": [],
								"queryType30": [],
								"queryType31": [],
								"queryType10Show": false,
								"queryType20Show": false,
								"queryType30Show": false,
								"queryType31Show": false,
						
								"activeType": 1
							}
						})
					} else {
						localStorage.removeItem('token')
						this.$message.error(res.msg);
						this.$router.push('/login')
					}
				}).catch((res) => {
					localStorage.removeItem('token')
					this.$message.error('请求失败，请重新登录！');
					this.$router.push('/login')
				})
			},
			getStoreDetail(itemData, queryType, name) {
				let data = {
					"queryType": queryType, //查询类型，10:本月收入 20:今日收入 30:本月支出一级 31:本月支出二级
					"dates": dayjs(this.dateValue).format("YYYY-MM-DD"), //查询日期
					"storeId": 8 //门店id
				}
				if (queryType == '31') {
					data.name = name
				}
				http({
					method: 'post',
					url: '/prod-api/system/store/data/getDetail',
					data: data
				}).then((res) => {
					if (queryType == '10') {
						itemData["queryType10"] = res.data
						itemData["queryType10Show"] = !itemData["queryType10Show"]
					}
					if (queryType == '20') {
						itemData["queryType20"] = res.data
						itemData["queryType20Show"] = !itemData["queryType20Show"]
					}
					if (queryType == '30') {
						itemData["queryType30"] = res.data
						itemData["queryType30Show"] = !itemData["queryType30Show"]
					}
					if (queryType == '31') {
						itemData["queryType31"] = res.data
						itemData["queryType31Show"] = !itemData["queryType31Show"]
					}
				})

			},
			showModel(storeId, storeName) {
				this.queryTypeYyRc = 'day'
				this.queryTypeYksl = 'day'
				this.queryTypeCwSr = 'day'
				this.queryTypeCwLl = 'day'
				this.setType1ShowValue = '1'
				this.setType2ShowValue = '1'
				this.spinningTop = true
				this.spinningBot = true
				this.storeId = storeId
				this.storeTitle = storeName
				this.visible = true
				this.getExpense()
				this.getOrderCount()
			},
			// 月度支出
			getExpense() {
				http({
					method: 'post',
					url: '/prod-api/system/store/data/getExpense',
					data: {
						storeId: this.storeId
					}
				}).then((res) => {
					this.$nextTick(() => {
						let chart = this.$echarts.init(this.$refs.echarts)
						let xAxis = []
						let dataList = []
						if (res.data) {
							res.data.forEach((item, index) => {
								xAxis.push(item.attr2)
								dataList.push(item.attr1)
							})
						}
						chart.setOption({
							tooltip: {
								trigger: 'axis',
							},
							// legend: {
							// 	data: ['收入'],
							// 	top: 0,
							// 	itemWidth: 12,
							// 	    itemHeight: 10,
							// },
							xAxis: {
								data: xAxis
							},
							grid: {
								left: "1%",
								right: "0px",
								bottom: dataList.length > 12 ? "10%" : "1%",
								// top: "12%",
								top: "4%",
								containLabel: true
							},
							yAxis: {
								axisLabel: {
									formatter: '{value}'
								}

							},
							dataZoom: [{
								zoomLock: true,
								brushSelect:false,
								height: 15,
								show: dataList.length > 12,
								type: 'slider',
								realtime: true,
								startValue: 0,
								endValue: 11,
								xAxisIndex: [0],
								bottom: '4%',
								left: '30',
								borderColor: 'rgba(0,0,0,0)',
								textStyle: {
									color: '#fcd100',
								},
							}],
							series: [
								// 流光效果
								{
									name: '支出',
									type: 'bar',
									barWidth: 20,
									// 这里不要超出实际数据
									data: dataList,
									itemStyle: {
										// 颜色渐变
										color: '#fcd100'
									}
								},
							]
						}, true)
						this.spinningTop = false
					})
				})
			},
			// 月度收入
			getIncome() {
				http({
					method: 'post',
					url: '/prod-api/system/store/data/getIncome',
					data: {
						storeId: this.storeId,
						queryType: this.queryTypeCwSr
					}
				}).then((res) => {
					this.$nextTick(() => {
						let chart = this.$echarts.init(this.$refs.echarts)
						let xAxis = []
						let dataList = []
						if (res.data) {
							res.data.forEach((item) => {
								xAxis.push(item.attr2)
								dataList.push(item.attr1)
							})
						}
						let num = this.queryTypeCwSr == 'day' ? 30 : 12;
						chart.setOption({
							tooltip: {
								trigger: 'axis',
							},
							// legend: {
							// 	data: ['收入'],
							// 	top: 0,
							// 	itemWidth: 12,
							// 	    itemHeight: 10,
							// },
							xAxis: {
								data: xAxis
							},
							grid: {
								left: "1%",
								right: "0px",
								bottom: dataList.length > num ? "10%" : "1%",
								// top: "12%",
								top: "4%",
								containLabel: true
							},
							yAxis: {
								axisLabel: {
									formatter: '{value}'
								}

							},
							dataZoom: [{
								zoomLock: true,
								brushSelect:false,
								height: 15,
								show: dataList.length > num,
								type: 'slider',
								realtime: true,
								startValue: 0,
								endValue: num - 1,
								xAxisIndex: [0],
								bottom: '4%',
								left: '30',
								borderColor: 'rgba(0,0,0,0)',
								textStyle: {
									color: '#fcd100',
								},
							}],
							series: [
								// 流光效果
								{
									name: '收入',
									type: 'bar',
									barWidth: 20,
									// 这里不要超出实际数据
									data: dataList,
									itemStyle: {
										// 颜色渐变
										color: '#fcd100'
									}
								},
							]
						}, true)
						this.spinningTop = false
					})
				})
			},
			// 月度利润
			getProfit() {
				http({
					method: 'post',
					url: '/prod-api/system/store/data/getProfit',
					data: {
						storeId: this.storeId,
						// queryType: this.queryTypeCwLl
					}
				}).then((res) => {
					this.$nextTick(() => {
						let chart = this.$echarts.init(this.$refs.echarts)
						let xAxis = []
						let dataList = []
						if (res.data) {
							res.data.forEach((item) => {
								xAxis.push(item.attr2)
								dataList.push(item.attr1)
							})
						}
						chart.setOption({
							tooltip: {
								trigger: 'axis',
							},
							// legend: {
							// 	data: ['收入'],
							// 	top: 0,
							// 	itemWidth: 12,
							// 	    itemHeight: 10,
							// },
							xAxis: {
								data: xAxis
							},
							grid: {
								left: "1%",
								right: "0px",
								bottom: dataList.length > 12 ? "10%" : "1%",
								// top: "12%",
								top: "4%",
								containLabel: true
							},
							yAxis: {
								axisLabel: {
									formatter: '{value}'
								}
							
							},
							dataZoom: [{
								zoomLock: true,
								brushSelect:false,
								height: 15,
								show: dataList.length > 12,
								type: 'slider',
								realtime: true,
								startValue: 0,
								endValue: 11,
								xAxisIndex: [0],
								bottom: '4%',
								left: '30',
								borderColor: 'rgba(0,0,0,0)',
								textStyle: {
									color: '#fcd100',
								},
							}],
							series: [
								// 流光效果
								{
									name: '利润',
									type: 'bar',
									barWidth: 20,
									// 这里不要超出实际数据
									data: dataList,
									itemStyle: {
										// 颜色渐变
										color: '#fcd100'
									}
								},
							]
						}, true)
						this.spinningTop = false
					})
				})
			},
			// 收入、支出、利润
			async getCwAll() {
				let exList = await http({
					method: 'post',
					url: '/prod-api/system/store/data/getExpense',
					data: {
						storeId: this.storeId,
					}
				})
				let inList = await http({
					method: 'post',
					url: '/prod-api/system/store/data/getIncome',
					data: {
						storeId: this.storeId,
						queryType: 'month'
					}
				})
				let prList = await http({
					method: 'post',
					url: '/prod-api/system/store/data/getProfit',
					data: {
						storeId: this.storeId,
					}
				})

				let chart = this.$echarts.init(this.$refs.echarts)
				let xAxis = []
				let exValus = []
				let inValus = []
				let prValus = []
				exList.data.forEach((item) => {
					xAxis.push(item.attr2)
					exValus.push(item.attr1)
				})
				inList.data.forEach((item) => {
					inValus.push(item.attr1)
				})
				prList.data.forEach((item) => {
					prValus.push(item.attr1)
				})
				
				chart.setOption({
					tooltip: {
						trigger: 'axis',
					},
					// legend: {
					// 	data: ['收入'],
					// 	top: 0,
					// 	itemWidth: 12,
					// 	    itemHeight: 10,
					// },
					xAxis: {
						data: xAxis
					},
					grid: {
						left: "1%",
						right: "0px",
						bottom: exValus.length > 12 ? "10%" : "1%",
						// top: "12%",
						top: "4%",
						containLabel: true
					},
					yAxis: {
						axisLabel: {
							formatter: '{value}'
						}
					
					},
					dataZoom: [{
						zoomLock: true,
						brushSelect:false,
						height: 15,
						show: exValus.length > 12,
						type: 'slider',
						realtime: true,
						startValue: 0,
						endValue: 11,
						xAxisIndex: [0],
						bottom: '4%',
						left: '30',
						borderColor: 'rgba(0,0,0,0)',
						textStyle: {
							color: '#fcd100',
						},
					}],
					series: [{
							name: '支出',
							type: 'bar',
							barWidth: 20,
							// 这里不要超出实际数据
							data: exValus,
							itemStyle: {
								// 颜色渐变
								color: '#fcd100'
							}
						},
						{
							name: '收入',
							type: 'bar',
							barWidth: 20,
							// 这里不要超出实际数据
							data: inValus,
							itemStyle: {
								// 颜色渐变
								color: '#ff9e30'
							}
						},
						{
							name: '利润',
							type: 'bar',
							barWidth: 20,
							// 这里不要超出实际数据
							data: prValus,
							itemStyle: {
								// 颜色渐变
								color: '#00abfc'
							}
						},
					]
				}, true)
				this.spinningTop = false
			},
			// 人次
			getOrderCount() {
				http({
					method: 'post',
					url: '/prod-api/system/store/data/getOrderCount',
					data: {
						storeId: this.storeId,
						queryType: this.queryTypeYyRc
					}
				}).then((res) => {
					this.$nextTick(() => {
						let chart = this.$echarts.init(this.$refs.echartsbox1)
						let xAxis = []
						let dataList = []
						let num = this.queryTypeYyRc == 'day' ? 30 : 12;
						if (res.data) {
							res.data.forEach((item) => {
								xAxis.push(item.attr2)
								dataList.push(item.attr1)
							})
						}
						chart.setOption({
							tooltip: {
								trigger: 'axis',
							},
							// legend: {
							// 	data: ['收入'],
							// 	top: 0,
							// 	itemWidth: 12,
							// 	    itemHeight: 10,
							// },
							xAxis: {
								data: xAxis
							},
							grid: {
								left: "1%",
								right: "0px",
								bottom: dataList.length > num ? "10%" : "1%",
								// top: "12%",
								top: "4%",
								containLabel: true
							},
							yAxis: {
								axisLabel: {
									formatter: '{value}'
								}
							
							},
							dataZoom: [{
								zoomLock: true,
								brushSelect:false,
								height: 15,
								show: dataList.length > num,
								type: 'slider',
								realtime: true,
								startValue: 0,
								endValue: num - 1,
								xAxisIndex: [0],
								bottom: '4%',
								left: '30',
								borderColor: 'rgba(0,0,0,0)',
								textStyle: {
									color: '#fcd100',
								},
							}],
							series: [
								// 流光效果
								{
									name: '人次',
									type: 'bar',
									barWidth: 20,
									// 这里不要超出实际数据
									data: dataList,
									itemStyle: {
										// 颜色渐变
										color: '#ff9e30'
									}
								},
							]
						})
						this.spinningBot = false
					})
				})
			},
			// 当前存续月卡会员数量
			getMemberCount() {
				http({
					method: 'post',
					url: '/prod-api/system/store/data/getMemberCount',
					data: {
						storeId: this.storeId,
						// queryType: this.queryTypeYksl
					}
				}).then((res) => {
					this.$nextTick(() => {
						let chart = this.$echarts.init(this.$refs.echartsbox1)
						let xAxis = []
						let dataList = []
						if (res.data) {
							res.data.forEach((item) => {
								xAxis.push(item.attr2)
								dataList.push(item.attr1)
							})
						}
						chart.setOption({
							tooltip: {
								trigger: 'axis',
							},
							// legend: {
							// 	data: ['收入'],
							// 	top: 0,
							// 	itemWidth: 12,
							// 	    itemHeight: 10,
							// },
							xAxis: {
								data: xAxis
							},
							grid: {
								left: "1%",
								right: "0px",
								bottom: dataList.length > 30 ? "10%" : "1%",
								// top: "12%",
								top: "4%",
								containLabel: true
							},
							yAxis: {
								axisLabel: {
									formatter: '{value}'
								}
							
							},
							dataZoom: [{
								zoomLock: true,
								brushSelect:false,
								height: 15,
								show: dataList.length > 30,
								type: 'slider',
								realtime: true,
								startValue: 0,
								endValue: 29,
								xAxisIndex: [0],
								bottom: '4%',
								left: '30',
								borderColor: 'rgba(0,0,0,0)',
								textStyle: {
									color: '#fcd100',
								},
							}],
							series: [
								// 流光效果
								{
									name: '会员数量',
									type: 'bar',
									barWidth: 20,
									// 这里不要超出实际数据
									data: dataList,
									itemStyle: {
										// 颜色渐变
										color: '#ff9e30'
									}
								},
							]
						})
						this.spinningBot = false
					})
				})
			},
			setType1Show() {
				this.spinningTop = true
				if (this.setType1ShowValue == 1) {
					this.getExpense()
				} else if (this.setType1ShowValue == 2) {
					this.getIncome()
				} else if (this.setType1ShowValue == 3) {
					this.getProfit()
				} else if (this.setType1ShowValue == 4) {
					this.getCwAll()
				}
			},
			setType2Show() {
				this.spinningBot = true
				if (this.setType2ShowValue == 1) {
					this.getOrderCount()
				} else {
					this.getMemberCount()
				}
			},
			logout() {
				localStorage.removeItem("token")
				this.$router.push('/login')
			}
		}
	}
</script>


<style lang="less" scoped>
	.header {
		height: 60px;
		line-height: 56px;
		z-index: 19;
		width: 100%;
		padding-block: 0;
		padding-inline: 0;
		border-block-end: 1px solid rgba(5, 5, 5, 0.06);
		background-color: rgba(255, 255, 255, 1);
		-webkit-backdrop-filter: blur(8px);
		backdrop-filter: blur(8px);
		transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo {
			display: flex;
			align-items: center;
			padding-left: 6px;

			img {
				height: 58px;
			}

			p {
				margin: 0px;
				font-size: 24px;
				height: 32px;
				margin-block: 0;
				margin-inline: 0;
				font-weight: 600;
				color: rgba(0, 0, 0, 0.88);
				font-size: 18px;
				line-height: 32px;
				position: relative;
				left: -4px;
			}
		}

		.user-box {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;
			margin-left: 16px;
			cursor: pointer;
			color: rgba(0, 0, 0, 0.45);
			height: 44px;
			color: rgba(0, 0, 0, 0.45);
			padding-inline: 8px;
			padding-block: 8px;
			cursor: pointer;
			display: flex;
			align-items: center;
			line-height: 44px;
			border-radius: 6px;
			border-radius: 10px;
		}

		.user-box:hover {
			background-color: rgba(0, 0, 0, 0.03);
		}

		.user-box img {
			width: 28px;
			height: 28px;
			line-height: 28px;
			font-size: 18px;
			border-radius: 100px;
			overflow: hidden;
		}

		.user-box span {
			margin-inline-start: 8px;
			font-size: 14px;
			font-weight: 700;
			color: rgba(0, 0, 0, 0.45);
		}
	}

	.list {
		padding: 40px;
		display: flex;
		flex-wrap: wrap;

		.item {
			background-color: #fcd100;
			width: 300px;
			padding-bottom: 40px;
			border-radius: 20px;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
			padding-top: 20px;
			margin-bottom: 40px;
			margin-right: 40px;

			.top {
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				.logoimg {
					height: 60px;
				}

				.date {
					position: absolute;
					right: 20px;
					top: -5px;
				}
			}

			.title {
				text-align: center;
				font-weight: 700;
				font-size: 18px;
				margin-top: -2px;
			}
		}
	}

	.datas {
		padding: 0 20px;
		padding-top: 4px;

		.data-item {
			margin-top: 10px;

			.data-text {
				display: flex;
				align-items: center;
				height: 30px;

				.name {
					margin-bottom: 0px;
					font-size: 16px;
					width: 160px;
				}

				.link {
					font-size: 12px;
					cursor: pointer;

					i {
						margin-left: 4px;
					}
				}
			}
		}

		.chil2 {
			padding-left: 20px;
			padding-left: 33px;
			padding-top: 6px;

			p {
				margin-bottom: 0px;
				font-size: 14px;
				margin-bottom: 6px;
			}
		}
	}

	.iconted {
		transition: all 0.3s;
	}

	.iconactive {
		transform: rotate(180deg);
	}

	.ppbx {
		display: flex;

		p {
			width: 127px;
		}

		.link {
			font-size: 12px;
			cursor: pointer;

			i {
				margin-left: 4px;
			}
		}
	}

	.types-box {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;

		.types {
			height: 24px;
			width: 140px;
			border-radius: 100px;
			border: 1px solid #333;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				width: 50%;
				text-align: center;
				position: relative;
				font-size: 12px;
				cursor: pointer;

				&:nth-child(1):after {
					content: "";
					height: 12px;
					width: 1px;
					background-color: #333;
					position: absolute;
					top: 3px;
					right: 0px;
				}

				&.active {
					color: #fff;
				}
			}
		}
	}

	.titlebx {
		height: 30px;
		line-height: 30px;
		position: relative;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: center;

		&:before {
			content: "";
			height: 20px;
			width: 4px;
			background-color: #fcd100;
			margin-right: 10px;
		}
	}

	.textboto {
		text-align: center;
		font-weight: 700;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			color: #fff;
		}
	}
</style>