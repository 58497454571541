<template>
	<div class="logback">
		<!-- <div class="img10"></div> -->
		<div class="form">
			<img src="../assets/acatve.png" class="logo2" alt="" />
			<div class="title">
				<h3>蒸汽猫无人健身房数据平台</h3>
			</div>
			<div class="form-input-box">
				<p>账号</p>
				<a-input v-model:value="form.adminName" size="large" :bordered="false" allowClear class="input2"
					placeholder="请输入账号">
					<template #prefix>
						<a-icon type="user" style="font-size: 16px;position: relative;top: 1px;color: #a1a1a1;" />
					</template>
				</a-input>
			</div>
			<div class="form-input-box">
				<p>密码</p>
				<a-input-password v-model:value="form.password" type="password" size="large" :bordered="false"
					allowClear class="input2" placeholder="请输入密码">
					<template #prefix>
						<a-icon type="lock" style="font-size: 16px;position: relative;top: 1px;color: #a1a1a1;" />
					</template>
				</a-input-password>
			</div>
			<div class="form-input-box">
				<p>验证码</p>
				<a-input v-model:value="form.code" size="large" placeholder="验证码">
					<a-icon slot="prefix" type="code"
						style="font-size: 16px;position: relative;top: 1px;color: #a1a1a1;" />
					<img @click="getCodeImg" :src="'data:image/png;base64,' + imgCode" class="imgcode" slot="suffix"
						alt="">
				</a-input>
			</div>
			<div>
				<a-checkbox style="color:#b8b8b8" v-model:checked="checked">记住密码</a-checkbox>
			</div>
			<div class="form-button" @click="formLogin">登录</div>
		</div>
	</div>
</template>

<script>
	import http from '../http'
	export default {
		name: 'home',
		data: function() {
			return {
				form: {
					adminName: '',
					password: '',
					code: ''
				},
				imgCode: '',
				imgCodeUUid: '',
				checked: false
			}
		},
		mounted() {
			this.getCodeImg()
		},
		methods: {
			getCodeImg() {
				http({
					method: 'get',
					url: '/prod-api/captchaImage',
					data: {

					}
				}).then((res) => {
					console.log(res, 'resres')
					this.imgCode = res.img
					this.imgCodeUUid = res.uuid
				})
			},
			formLogin() {
				http({
					method: 'post',
					url: '/prod-api/login',
					data: {
						"username": this.form.adminName, //登录账号
						"password": this.form.password, //密码
						"code": this.form.code, //图形验证码
						"uuid": this.imgCodeUUid, //uuid
						"userType": "10" //加盟商固定传：10
					}
				}).then((res) => {
					if (res.code == 200) {
						// this.$message.success('登录成功！');
						localStorage.setItem("token", res.token)
						this.$router.push('/home')
					} if (res.code == 500 && res.msg == '验证码已失效') {
						this.$message.error(res.msg);
						this.getCodeImg()
					} else {
						this.getCodeImg()
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.logback {
		// background-image: url(../../assets/WechatIMG123.png);
		width: 100%;
		height: 100vh;
		background-size: cover;
		background-color: #fcd100;
		// background-position: -100px 0px;
	}

	.form {
		width: 420px;
		height: 580px;
		background-color: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -210px;
		margin-top: -290px;
		border-radius: 10px;
		padding: 40px 60px;

		.title {
			margin-bottom: 30px;

			h3 {
				font-size: 22px;
				text-align: center;
				width: 100%;
				display: block;
			}

			span {
				font-size: 18px;
				margin-left: 12px;
				color: #8281a7;
			}
		}

		.img {
			width: 16px;
			height: 18px;
			margin-right: 4px;
		}

		.form-input-box {
			margin-bottom: 16px;

			&>p {
				color: #b8b8b8;
				font-size: 14px;
				margin-bottom: 6px;
			}
		}

		.input2 {

			input {
				background: #f5f5f9;
			}
		}

		.form-button {
			width: 300px;
			height: 44px;
			background-color: #fcd100;
			color: #fff;
			font-size: 18px;
			text-align: center;
			line-height: 44px;
			margin-top: 30px;
			border-radius: 8px;
			cursor: pointer;
		}
	}

	.imgcode {
		width: 100px;
		display: block;
		height: 40px;
		position: relative;
		right: -11px;
		cursor: pointer;
	}
	.logo2 {
		margin: 0 auto;
		display: block;
		border-radius: 100px;
		width: 70px;
		height: 70px;
		margin-bottom: 7px;
	}
</style>